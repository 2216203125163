import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "glossary",
      "style": {
        "position": "relative"
      }
    }}>{`Glossary`}<a parentName="h1" {...{
        "href": "#glossary",
        "aria-label": "glossary permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`We have collected here a number of methods, techniques, and practices which can help you understand different parts of somatic dance and the sorts of movement we are interested in exploring together. It is important to note that you do not need to know any of these to participate in our sessions - we curate them here only for those interested in understanding more of the background which informs how we approach this guild. Antonio has a rich history in both dance and the academy, and we are lucky to have people like Marlon fron dance-tech and Reyna and Jeanne from MotionDAO participating with us, all of whom add immeasurably to the knowledge base which can help you understand intellectually what your body already knows.`}</p>
    <List mdxType="List">
      <Link to="https://alexandertechnique.com/" mdxType="Link">
        <p>{`Alexander Technique`}</p>
      </Link>
      <Link to="https://www.goodtherapy.org/learn-about-therapy/types/feldenkrais-method" mdxType="Link">
        <p>{`Feldenkrais Method`}</p>
      </Link>
      <Link to="https://www.bodymindcentering.com/" mdxType="Link">
        <p>{`Body Mind Centering`}</p>
      </Link>
      <Link to="http://trance-dance.net/what-is-trance-dance/" mdxType="Link">
        <p>{`Trance Dance`}</p>
      </Link>
      <Link to="https://www.healthline.com/health/exercise-fitness/hatha-vs-vinyasa" mdxType="Link">
        <p>{`Vinyasa Flow Yoga`}</p>
      </Link>
      <Link to="https://www.thewonderfulworldofdance.com/wesley-ensminger-about-release-technique-and-exploration-of-new-ways-a-body-can-move" mdxType="Link">
        <p>{`Release Technique`}</p>
      </Link>
      <Link to="https://www.mercecunningham.org/the-work/cunningham-technique/" mdxType="Link">
        <p>{`Cunningham Technique`}</p>
      </Link>
      <Link to="https://contactquarterly.com/contact-improvisation/about/" mdxType="Link">
        <p>{`Contact Improvisation`}</p>
      </Link>
      <Link to="https://planetarydance.org/" mdxType="Link">
        <p>{`Plantetary Dance`}</p>
      </Link>
    </List>
    <Video src="https://www.youtube-nocookie.com/embed/QdkAlYXQJs0" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      